import React from 'react';
// import { graphql, useStaticQuery, Link } from 'gatsby';

import Layout from 'src/components/layout';

// import HeroBanner from 'src/components/page/cta/hero-banner';
// <HeroBanner />

// import InstaReel from 'src/components/accessory/social/insta-reel';
// <InstaReel />

// import PostList from 'src/components/post/list';
// <PostList />

export default () => {
	return (
		<>
			<Layout>
				<article className="page-content centered">
					<h1 className="page-main-heading">
						<span className="name-brand">
							Tales of the <br /> Code and pixel!
						</span>
						The Creative
						<br />
						and
						<br />
						Technical works
						<br />
						of
						<br />
						<span class="name-brand">David Pineda</span>
					</h1>
					<h2 className="sub-heading home-page">
						My art, some code,
						<br />
						weird thoughts on tech,
						<br />
						and some of my dreams for the future.
					</h2>
				</article>
			</Layout>
		</>
	);
};
